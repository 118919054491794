<template>
  <div>
    <vx-card actionable class="cardx" title>
      <vs-row>
        <vs-col vs-w="6">
          <h4>Whatsapp Broadcast</h4>
        </vs-col>

        <!-- <vs-col vs-w="6" v-if="showCount">
          <vs-button
            size="medium"
            color="primary"
            @click="broadcastWAData()"
            class="broadcast-control"
            id="broadcastbtn-visible"
            >Broadcast</vs-button
          >
        </vs-col> -->
      </vs-row>
      <br />
      <vs-row>
        <vs-col vs-w="6" class="whatsappbtnsection">
          <vs-card>
            <vs-row>
              <vs-col>
                <vs-alert
                  :active.sync="FilterError"
                  color="danger"
                  closable
                  close-icon="close"
                >
                  Please Select
                  <b>Course</b>
                  <b>Level</b> and
                  <b>Spoc</b>
                </vs-alert>
              </vs-col>
            </vs-row>
            <br />
            <vs-row>
              <vs-col
                vs-w="12"
                vs-justify="center"
                vs-align="center"
                style="display: flex"
              >
                <vs-radio
                  v-if="getrole('cm1') || getrole('cm4') || getrole('wh2')"
                  v-model="select_role"
                  vs-value="CM"
                  >CM</vs-radio
                >&nbsp;&nbsp;
                <vs-radio
                  v-if="getrole('exed2') || getrole('cm4') || getrole('wh2')"
                  v-model="select_role"
                  vs-value="IIML"
                  >Exed</vs-radio
                >
              </vs-col>
            </vs-row>
            <vs-row class="mt-5">
              <vs-col
                vs-w="12"
                vs-justify="center"
                vs-align="center"
                style="display: flex"
              >
                <div v-if="select_role == 'CM'">
                  <div class="flex flex-row gap-4">
                    <vs-checkbox
                      v-for="(course, index) in CM_courses_checkbox"
                      :key="index"
                      color="dark"
                      class="mr-2"
                      :vs-value="course"
                      v-model="initSelectedCourses"
                      >{{ course }}</vs-checkbox
                    >
                  </div>
                </div>
                <div v-if="select_role == 'IIML'">
                  <div class="flex flex-row gap-4">
                    <!-- <div class="mr-2">something</div>
                    <div>something</div>
                    <div>something</div>
                    <div>something</div>-->
                    <vs-checkbox
                      v-for="(course, index) in IIML_courses_checkbox"
                      :key="index"
                      color="dark"
                      v-model="initSelectedCourses"
                      :vs-value="course"
                      class="mr-2"
                      >{{ course }}</vs-checkbox
                    >
                  </div>
                </div>
              </vs-col>
            </vs-row>
            <div class="flex justify-around mt-5">
              <vs-row>
                <vs-col
                  vs-w="4"
                  vs-justify="flex-end"
                  vs-align="center"
                  style="display: flex"
                >
                  <span style="font-size: 15px; font-weight: bold">Levels</span>
                </vs-col>
                <vs-col
                  vs-w="4"
                  vs-justify="flex-end"
                  vs-align="center"
                  style="display: flex"
                >
                  <div v-if="select_role == 'CM'">
                    <treeselect
                      v-if="
                        getSelectedCource !== 'IIML-FA' || getSelectedCource !== 'IIML-SF' || getSelectedCource !== 'IIML-FT'
                      "
                      style="width: width: 100%;"
                      placeholder="Levels"
                      v-model="initSelectedLevels"
                      :multiple="true"
                      :options="treeDataLevels"
                    />
                    <treeselect
                      v-else
                      style="width: width: 100%;"
                      placeholder="IIML Levels"
                      v-model="initSelectedLevelsIIML"
                      :multiple="true"
                      :options="treeDataLevelsIIML"
                    />
                  </div>
                  <div v-if="select_role == 'IIML'">
                    <treeselect
                      style="width: width: 100%;"
                      placeholder="IIML Levels"
                      v-model="initSelectedLevelsIIML"
                      :multiple="true"
                      :options="treeDataLevelsIIML"
                    />
                  </div>
                </vs-col>
              </vs-row>
            </div>
            <div class="flex justify-around mt-5">
              <vs-row>
                <vs-col
                  vs-w="4"
                  vs-justify="flex-end"
                  vs-align="center"
                  style="display: flex"
                >
                  <span style="font-size: 15px; font-weight: bold">Focus</span>
                </vs-col>
                <vs-col
                  vs-w="4"
                  vs-justify="flex-end"
                  vs-align="center"
                  style="display: flex"
                >
                  <treeselect
                    placeholder="Focus"
                    style="width: 250px"
                    v-model="initSelected"
                    :options="treeDatafocus"
                  />
                </vs-col>
              </vs-row>
            </div>
            <div class="flex justify-around mt-5">
							<vs-row>
								<vs-col
									vs-w="4"
									vs-justify="flex-end"
									vs-align="center"
									style="display: flex"
								>
									<span style="font-size: 15px; font-weight: bold">Spocs</span>
								</vs-col>
								<vs-col
									vs-w="6"
									vs-justify="flex-end"
									vs-align="center"
									style="display: flex"
								>
									<treeselect
										style="width: 250px"
										placeholder="Spocs"
										v-model="initSelectedBDE"
										:multiple="true"
										:options="treeDataBDE"
										:disabled="disabledspocstree"
									/>
								</vs-col>
							</vs-row>
						</div>
            <div class="flex justify-around mt-5">
              <vs-row>
                <vs-col
                  vs-w="4"
                  vs-justify="flex-end"
                  vs-align="center"
                  style="display: flex"
                >
                  <span style="font-size: 15px; font-weight: bold">Daily Limit</span>
                </vs-col>
                <vs-col
                  vs-w="4"
                  vs-justify="flex-end"
                  vs-align="center"
                  style="display: flex"
                >
                  <vs-input
                    class="inputx"
                    type="number"
                    style="width: 250px"
                    placeholder="Daily Limit"
                    v-model="daily_limit"
                  />
                </vs-col>
              </vs-row>
            </div>
            <!-- <vs-row>
              <vs-col vs-w="6" style="display:flex">
                <vs-radio v-model="select_role" vs-value="CM">CM</vs-radio>&nbsp;&nbsp;
                <vs-radio v-model="select_role" vs-value="IIML">IIML</vs-radio>
              </vs-col>
              <vs-col vs-w="6">
                <vs-dropdown class="buttonrow" vs-custom-content vs-trigger-click>
                  <vs-button class="buttonlist">
                    <a class="a-icon" style="color:#ffffff" href.prevent @click="showTreeBox">
                      Courses
                      <i class="material-icons">expand_more</i>
                    </a>
                  </vs-button>
                  <vs-dropdown-menu class="customDropDown">
                    <treeselect
                      v-model="initSelectedCourses"
                      :multiple="true"
                      :options="treeDataCourses"
                    />
                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-dropdown
                  class="buttonrow"
                  v-show="showingLevels"
                  vs-custom-content
                  vs-trigger-click
                >
                  <vs-button class="buttonlist">
                    <a class="a-icon" style="color:#ffffff" href.prevent @click="showTreeBox">
                      Levels
                      <i class="material-icons">expand_more</i>
                    </a>
                  </vs-button>
                  <vs-dropdown-menu class="dropdown-login customDropDown">
                    <treeselect
                      v-model="initSelectedLevels"
                      :multiple="true"
                      :options="treeDataLevels"
                    />
                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-dropdown
                  class="buttonrow"
                  v-show="showingIIMLLevels"
                  vs-custom-content
                  vs-trigger-click
                >
                  <vs-button class="buttonlist">
                    <a class="a-icon" style="color:#ffffff" href.prevent @click="showTreeBox">
                      IIML Levels
                      <i class="material-icons">expand_more</i>
                    </a>
                  </vs-button>
                  <vs-dropdown-menu class="customDropDown">
                    <treeselect
                      v-model="initSelectedLevelsIIML"
                      :multiple="true"
                      :options="treeDataLevelsIIML"
                    />
                  </vs-dropdown-menu>
                </vs-dropdown>
                <vs-dropdown class="buttonrow" vs-custom-content vs-trigger-click>
                  <vs-button class="buttonlist">
                    <a class="a-icon" style="color:#ffffff" href.prevent @click="showTreeBox">
                      SPOC
                      <i class="material-icons">expand_more</i>
                    </a>
                  </vs-button>
                  <vs-dropdown-menu class="customDropDown">
                    <treeselect v-model="initSelectedBDE" :multiple="true" :options="treeDataBDE" />
                  </vs-dropdown-menu>
                </vs-dropdown>
              </vs-col>
            </vs-row>-->
            <vs-row
              style="
                margin-top: 2%;
                border: 2px solid darkgrey;
                border-radius: 5px;
                min-height: 35px;
              "
            >
              <vs-col
                style="display: contents"
                vs-type="flex"
                vs-justify="flex-start"
                vs-align="center"
                vs-w="12"
              >
                <div v-for="(chip, index) in searchCriteria" :key="index">
                  <vs-chip
                    style="margin: 5px"
                    v-if="chip !== 'divider'"
                    closable
                    @click="removechip(chip)"
                    color="primary"
                  >
                    <b>{{ chip }}</b>
                  </vs-chip>
                  <div v-else>
                    <div class="vl"></div>
                  </div>
                </div>
              </vs-col>
            </vs-row>
            <vs-row class="gap">
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="5">
                <p style="font-size: 14px; font-weight: bold">Count :</p>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="1">
                <div style="font-size: 14px; font-weight: bold">{{ this.WACount }}</div>
              </vs-col>
              <vs-col vs-type="flex" vs-justify="flex-end" vs-align="center" vs-w="3">
                <vs-button
                  style="margin: 5px; bottom: 0px; font-size: small"
                  color="dark"
                  size="small"
                  class="gap"
                  type="gradient"
                  @click="getWABCount()"
                  >Get Count</vs-button
                >
              </vs-col>
            </vs-row>
            <vs-row>
              <vs-col vs-w="12" style="justify-content: center; display: flex">
                <vs-button
                  size="small"
                  class="gap"
                  color="dark"
                  type="gradient"
                  icon="refresh"
                  style="margin: 5px; bottom: 0px; font-size: small"
                  @click="refreshdata"
                  >refresh</vs-button
                >
                <vs-button
                  size="small"
                  class="gap"
                  color="dark"
                  type="gradient"
                  style="margin: 5px; bottom: 0px; font-size: small"
                  @click="checkBroadcastStatus"
                  >Create Broadcast</vs-button
                >
                <vs-chip
                  style="margin: 5px; bottom: 0px; font-size: small"
                  v-if="showCount"
                  color="primary"
                >
                  <b>{{ leadCount }}</b>
                </vs-chip>
              </vs-col>
            </vs-row>
          </vs-card>
        </vs-col>
        <vs-col vs-w="6">
          <vs-card>
            <vs-row>
              <vs-col>
                <vs-alert
                  :active.sync="messageError"
                  color="danger"
                  closable
                  close-icon="close"
                  >Please Select Message</vs-alert
                >
              </vs-col>
            </vs-row>
            <!-- <vs-list style="height: 350px">
              <vs-list-header title="Messages"></vs-list-header>

              <div>
                <vs-list-item
                  v-for="whatsappMessage in whatsappMessages"
                  :key="whatsappMessage.id"
                  :title="whatsappMessage.name"
                  :data-tooltip="whatsappMessage.message"
                >
                  <vs-radio
                    color="success"
                    v-model="selectedWhatsappMessage"
                    :vs-value="whatsappMessage.id"
                  ></vs-radio>
                </vs-list-item>
              </div>
            </vs-list> -->
			<vs-table max-items="8" search pagination :data="whatsappMessages">
				<template slot="header">
					<h5 style="position: relative; left: 20px">Messages</h5>
				</template>
				<!-- <template slot="thead">
					<vs-th> Messages </vs-th>
				</template> -->

				<template slot-scope="{ data }">
					<vs-tr :key="indextr" v-for="(tr, indextr) in data">
						<vs-td :data="tr.id" :data-tooltip="tr.message">
							<div>
								<vs-radio
									color="success"
									v-model="selectedWhatsappMessage"
									:vs-value="tr.id"
								></vs-radio>
								<span>{{ tr.name }} </span>
							</div>
						</vs-td>
					</vs-tr>
				</template>
			</vs-table>
          </vs-card>
        </vs-col>
      </vs-row>
      <input type="hidden" id="broadcastFilters" :value="broadcastFiltersvalue" />
    </vx-card>
  </div>
</template>

<script>
// import _ from "lodash";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import vSelect from "vue-select";
import "vue-tree-halower/dist/halower-tree.min.css";
import LeadsNavBar from "../pagesComponents/LeadsNavBar.vue";
import FocusSearch from "../pagesComponents/FocusSearch.vue";
import SpocSearchTree from "../pagesComponents/SPOCSearchTree.vue";
import Datepicker from "vuejs-datepicker";
import DateRangePicker from "vue2-daterange-picker";
import EventBus from "../eventbus.js";
import { VTree, VSelectTree } from "vue-tree-halower";
import axios from "axios";
import constants from "../../../constants.json";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import "vue-select/dist/vue-select.css";
export default {
  components: {
    Datepicker,
    LeadsNavBar,
    VTree,
    VSelectTree,
    DateRangePicker,
    FocusSearch,
    SpocSearchTree,
    Treeselect,
    "v-select": vSelect,
    VuePerfectScrollbar,
  },
  data() {
    return {
      WACount: "",
      loadShow: true,
      countShow: false,
      disabledspocstree: true,
      daily_limit: "500",
      treeDatafocus: [
        {
          id: "delays",
          label: "Delays",
        },
        {
          id: "today",
          label: "Today",
        },
        {
          id: "waiting",
          label: "Waiting",
        },
        {
          id: "untapped",
          label: "Untapped",
        },
      ],
      select_role: "CM",
      messageError: false,
      showCount: false,
      leadCount: 0,
      FilterError: false,
      whatsappMessages: [],
      selectedWhatsappMessage: "",
      broadcastFiltersvalue: "",
      showingLevels: false,
      showingIIMLLevels: false,
      spocLogged: false,
      canIdWidth: "100px",
      initSelected: undefined,
      initSelectedBDE: [],
      initSelectedBDESR: [],
      initSelectedCourses: [],
      initSelectedLevels: [],
      initSelectedLevelsIIML: [],
      treeDataBDE: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      treeDataBDESR: [
        {
          id: "All",
          label: "All",
          children: [],
        },
      ],
      // CM_courses_checkbox: ["CPA", "CMA", "CFA", "FRM", "USP", "CPA-AA","IIMI-BA", "IIMI-YLP","RPO", "IIML-FA", "IIML-SF", "IIML-FT",],
      CM_courses_checkbox: ["CPA", "CMA", "CFA", "FRM", "USP", "CPA-AA", "IIML-FT", "AICPA", "CPA-US"],

      IIML_courses_checkbox: [
        // "IIML-BA",
        // "IIML-PM",
        // "IIML-HR",
        // "IITR-BF",
        // "IITR-DB",
        // "IITM-AA",
        // "IIMK-CX",
        // "IITM-FS",
        // "IITR-CC",
        // "IIMK-FT",
        // "IIML-AB",
        // "IIML-SH",
        // "IITJ-DE",
        "XLRI-HR",
        "XLRI-SH",
        "IIMI-BA",
        "IIMI-AA",
        // "XLRI-DM",
        // "IIML-FT"
      ],
      treeDataCourses: [],
      CM_courses: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "CPA",
              label: "CPA",
            },
            // {
            //   id: "AICPA",
            //   label: "AICPA",
            // },
            {
              id: "CMA",
              label: "CMA",
            },
            {
              id: "CFA",
              label: "CFA",
            },
            {
              id: "FRM",
              label: "FRM",
            },
            {
              id: "USP",
              label: "USP",
            },
            {
              id: "IIML-FA",
              label: "IIML-FA",
            },
            {
              id: "IIML-FT",
              label: "IIML-FT",
            },
            {
              id: "IIML-SF",
              label: "IIML-SF",
            },
          ],
        },
      ],
      IIML_courses: [
        {
          id: "All",
          label: "All",
          children: [
            // {
            //   id: "IIML-FA",
            //   label: "IIML-FA",
            // },
            {
              id: "IIML-DA",
              label: "IIML-DA",
            },
            {
              id: "IIML-HR",
              label: "IIML-HR",
            },
            {
              id: "IIML-PM",
              label: "IIML-PM",
            },
            {
              id: "IITR-BF",
              label: "IITR-BF",
            },
            {
              id: "IITR-DB",
              label: "IITR-DB",
            },
            {
              id: "IITM-AA",
              label: "IITM-AA",
            },
            {
              id: "IIMK-CX",
              label: "IIMK-CX",
            },
            {
              id: "IITM-FS",
              label: "IITM-FS",
            },
            {
              id: "IITR-CC",
              label: "IITR-CC",
            },
            {
              id: "IIMK-FT",
              label: "IIMK-FT",
            },
            {
              id: "IIML-AB",
              label: "IIML-AB",
            },
            {
              id: "IIML-SH",
              label: "IIML-SH",
            },
            {
              id: "IITJ-DE",
              label: "IITJ-DE",
            },
            {
              id: "XLRI-HR",
              label: "XLRI-HR",
            },
            {
              id: "XLRI-SH",
              label: "XLRI-SH",
            },
            {
              id: "IIMI-BA",
              label: "IIMI-BA",
            },
            {
              id: "IIMI-AA",
              label: "IIMI-AA",
            },
            {
              id: "XLRI-DM",
              label: "XLRI-DM",
            },
            // {
            //   id: "MAYBE",
            //   label: "MAYBE"
            // }
          ],
        },
      ],
      treeDataLevels: [
        {
          id: "All",
          label: "All",
          children: [
            {
              id: "P1",
              label: "P1",
              children: [
                {
                  id: "M3",
                  label: "M3",
                },
                {
                  id: "M3+",
                  label: "M3+",
                },
                {
                  id: "M3++",
                  label: "M3++",
                },
                {
                  id: "M5",
                  label: "M5",
                },
                {
                  id: "M6",
                  label: "M6",
                },
                {
                  id: "M6 AR",
                  label: "M6 AR",
                },
                {
                  id: "M6 AR-",
                  label: "M6 AR-",
                },
              ],
            },
            {
              id: "P2",
              label: "P2",
              children: [
                // {
                //   id: "L2",
                //   label: "L2",
                // },
                // {
                //   id: "L3",
                //   label: "L3",
                // },
                // {
                //   id: "L4",
                //   label: "L4",
                // },
                // {
                //   id: "L6",
                //   label: "L6",
                // },
                // {
                //   id: "L5",
                //   label: "L5",
                // },
                // {
                //   id: "L3+",
                //   label: "L3+",
                // },
              ],
            },
            {
              id: "P3",
              label: "P3",
              children: [
                {
                  id: "M4-",
                  label: "M4-",
                },
                {
                  id: "M4",
                  label: "M4",
                },
                {
                  id: "M2",
                  label: "M2",
                },
                {
                  id: "M1",
                  label: "M1",
                },
                // {
                //   id: "L4-",
                //   label: "L4-",
                // },
                // {
                //   id: "L1",
                //   label: "L1",
                // },
              ],
            },
            {
              id: "M7",
              label: "M7",
            },
            // {
            //   id: "N/A",
            //   label: "N/A",
            // },
            // {
            //   id: "DND",
            //   label: "DND",
            // },
          ],
        },
      ],
      treeDataLevelsIIML: [
        {
          id: "All",
          label: "All",
          children: [
            { id: "Cold", label: "Cold" },
            { id: "Hot", label: "Hot" },
            { id: "Warm", label: "Warm" },
            { id: "Very Hot", label: "Very Hot" },
            { id: "Application Received", label: "Application Received" },
            { id: "Exam-Pass", label: "Exam-Pass" },
            { id: "Exam-Fail", label: "Exam-Fail" },
            { id: "Exam-Pass-Dropout", label: "Exam-Pass-Dropout" },
            { id: "Exam-Fail-Dropout", label: "Exam-Fail-Dropout" },
            { id: "Exam-Noshow-Dropout", label: "Exam-Noshow-Dropout" },
            { id: "Net Enquiry", label: "Net Enquiry" },
            { id: "Busy/NL", label: "Busy/NL" },
            { id: "Application Approved", label: "Application Approved" },
            { id: "Enrolled", label: "Enrolled" },
            { id: "Final payment Received", label: "Final payment Received" },
            { id: "NA", label: "NA" },
            // { id: "Enquiry", label: "Enquiry" },
            // { id: "RPO-M7", label: "RPO-M7" },
            // { id: "IIML-FA-M7", label: "IIML-FA-M7" },
            // { id: "IIML-SF-M7", label: "IIML-SF-M7" },
            // { id: "IIML-FT-M7", label: "IIML-FT-M7" },
            // { id: "IIML-BA-M7", label: "IIML-BA-M7" },
            // { id: "IIML-PM-M7", label: "IIML-PM-M7" },
            // { id: "IIML-HR-M7", label: "IIML-HR-M7" },
            // { id: "IITR-BF-M7", label: "IITR-BF-M7" },
            // { id: "IITR-DB-M7", label: "IITR-DB-M7" },
            // { id: "IITM-AA-M7", label: "IITM-AA-M7" },
            // { id: "IIMK-CX-M7", label: "IIMK-CX-M7" },
            // { id: "IITM-FS-M7", label: "IITM-FS-M7" },
            // { id: "IITR-CC-M7", label: "IITR-CC-M7" },
            // { id: "IIMK-FT-M7", label: "IIMK-FT-M7" },
            // { id: "IIML-AB-M7", label: "IIML-AB-M7" },
            // { id: "IIML-SH-M7", label: "IIML-SH-M7" },
            // { id: "IITJ-DE-M7", label: "IITJ-DE-M7" },
            // { id: "XLRI-HR-M7", label: "XLRI-HR-M7" },
            // { id: "IIMI-BA-M7", label: "IIMI-BA-M7" },
            // { id: "XLRI-DM-M7", label: "XLRI-DM-M7" },
            // { id: "CM-Yet-To-Connect", label: "CM-Yet-To-Connect" },
          ],
        },
      ],
      searchIn: {
        id: "",
        name: "",
      },
      rawBde: [],
      rawBdeSR: [],
      rawcities: [],
      SpocCityPrefilled: [],
      searchCriteria: [],
      count: 0,
      mwb_names: [],
      getSelectedCource: "",
    };
  },
  mounted() {
    if (this.getrole("cm1") || this.getrole("exed2")) {
      this.disabledspocstree = true;
    } else if (this.getrole("cm4") || this.getrole("wh2")) {
      this.disabledspocstree = false;
    }
    console.log("user role", localStorage.getItem("role"));
    console.log("selected role", this.select_role);
    if (this.select_role === "CM") {
      this.treeDataCourses = this.CM_courses;
      this.showingLevels = true;
      this.showingIIMLLevels = false;
      this.getSpocAdmin();
    }
    this.getwhatsappMessages();
    this.$store.commit("CLEAR_DASHBOARD_LEADS");
    if (
      localStorage.getItem("role") === "cm4" ||
      localStorage.getItem("role") === "wc" ||
      localStorage.getItem("role") === "ac2" ||
      localStorage.getItem("role") === "fe" ||
      localStorage.getItem("role") === "cmca" ||
      localStorage.getItem("role") === "baca" ||
      localStorage.getItem("role") === "hr" ||
      localStorage.getItem("role") === "wh2"
    ) {
      this.spocLogged = false;
      this.canIdWidth = "100%";
      this.getSpocAdmin();
    } else {
      this.spocLogged = true;
      this.canIdWidth = "100px";
      this.getSpoc();
    }
  },
  watch: {
    select_role: function (val) {
      this.initSelectedCourses = [];
      this.initSelectedLevelsIIML = [];
      this.initSelectedLevels = [];
      this.initSelectedBDE = [];
      if (val === "IIML") {
        this.treeDataCourses = this.IIML_courses;
        this.showingLevels = false;
        this.showingIIMLLevels = true;
        this.getSpocAdmin();
      } else if (val === "CM") {
        this.treeDataCourses = this.CM_courses;
        this.showingLevels = true;
        this.showingIIMLLevels = false;
        this.getSpocAdmin();
      }
    },
    selectedWhatsappMessage: function (val) {
      if (val !== "" || val !== undefined) {
        this.messageError = false;
      }
    },
    initSelectedBDESR: function () {
      this.mergeAllSearch();
    },
    initSelectedBDE: function () {
      this.mergeAllSearch();
    },
    initSelected: function () {
      this.mergeAllSearch();
    },
    initSelectedCourses: function (val) {
      console.log("selected cource", val[0]);
      this.getSelectedCource = val[0];
      this.mergeAllSearch();
    },
    initSelectedLevels: function () {
      this.mergeAllSearch();
    },
    initSelectedLevelsIIML: function () {
      this.mergeAllSearch();
    },
  },
  methods: {
    openLoadingInDiv() {
      this.$vs.loading({
        container: "#div-with-loading",
        scale: 0.6,
      });
      setTimeout(() => {
        this.$vs.loading.close("#div-with-loading > .con-vs-loading");
        this.loadShow = false;
        this.countShow = true;
      }, 3000);
    },
    broadcastWAData() {
      let obj = this.getSelectedParamsForRequests();
      let params = {
        payload: JSON.stringify(obj),
        total_pages: this.leadCount,
      };
      console.log(params);
      // return;
      axios
        .post(`${constants.MILES_CM_BACK}saveWABroadcastQuery`, params, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log(response);
          // this.showCount = true;
          if (response.data.status === "success") {
            obj.broardcast_request_id = response.data.broadcast_request_id;
            console.log("wa broadcast filters from server", obj);
            location.reload();
          }
          // this.leadCount = response.data.total;
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getWABCount() {
      let obj = this.getSelectedParamsForRequests();
      console.log(obj);
      axios
        .get(`${constants.MILES_CM_BACK}getWhatsAppBoradcastCount`, {
          params: obj,
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("WACount", response);
          if(response.data.status === "success"){
            this.$vs.notify({
              title: "Success",
              text: response.data.message,
              color: "success",
            });
            this.WACount = response.data.count;
          }
          if(response.data.status === "error"){
            this.$vs.notify({
              title: "Error",
              text: response.data.message,
              color: "danger",
            });
            this.WACount = response.data.count;
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    checkBroadcastStatus() {
      let obj = this.getSelectedParamsForRequests();
      console.log("checkBroadcastStatus",obj);
      if (obj.whats_app_message_id === "" || obj.whats_app_message_id === undefined) {
        this.messageError = true;
        return;
      }
      // return;
      axios
        .post(`${constants.MILES_CM_BACK}WhatsappBroadcast`, obj, {  
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("Create Broad cast",response);
          if(response.data.status === "success"){
            this.$vs.notify({
              title: "Success",
              text: response.data.message,
              color: "success",
            });
            this.showCount = true;
            this.leadCount = response.data;
            this.$emit("refresh-table");
          }
          if(response.data.status === "error"){
            this.$vs.notify({
              title: "Error",
              text: response.data.message,
              color: "danger",
            });
          }
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    removechip(chip) {
      let chipPlacement = "";
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr" ||
        localStorage.getItem("role") === "wh2"
      ) {
        this.treeDataBDE[0].children.forEach((child) => {
          child.children.forEach((child2) => {
            if (child2.label === chip) {
              chipPlacement = "bde";
            }
          });
        });
      } else {
        this.treeDataBDE[0].children.forEach((child) => {
          if (child.label === chip) {
            chipPlacement = "bde";
          }
        });
      }
      this.treeDataCourses[0].children.forEach((child) => {
        if (child.label === chip) {
          chipPlacement = "courses";
        }
      });
      this.treeDataLevelsIIML[0].children.forEach((child) => {
        if (child.label === chip) {
          chipPlacement = "iimllevels";
        }
      });
      this.treeDataLevels[0].children.forEach((child) => {
        if (chip === "M7" || chip === "N/A" || chip === "DND") {
          chipPlacement = "levels";
        } else {
          if ("children" in child) {
            child.children.forEach((chi) => {
              if (chi.label === chip) {
                chipPlacement = "levels";
              }
            });
          }
        }
      });
      if (this.initSelected === chip) {
        this.initSelected = undefined;
      }
      switch (chipPlacement) {
        case "bde":
          if (this.initSelectedBDE.indexOf(chip) === -1) {
            if (
              localStorage.getItem("role") === "cm4" ||
              localStorage.getItem("role") === "wc" ||
              localStorage.getItem("role") === "ac2" ||
              localStorage.getItem("role") === "fe" ||
              localStorage.getItem("role") === "cmca" ||
              localStorage.getItem("role") === "baca" ||
              localStorage.getItem("role") === "hr" ||
              localStorage.getItem("role") === "wh2"
            ) {
              if (this.initSelectedBDE.indexOf("All") !== -1) {
                this.initSelectedBDE = [];
                this.treeDataBDE[0].children.forEach((child) => {
                  child.children.forEach((child2) => {
                    if (child2.label !== chip) {
                      this.initSelectedBDE.push(child2.label);
                    }
                  });
                });
              } else {
                let city = "";
                this.treeDataBDE[0].children.forEach((child) => {
                  child.children.forEach((child2) => {
                    if (child2.label === chip) {
                      city = child.label;
                    }
                  });
                });
                this.initSelectedBDE.splice(this.initSelectedBDE.indexOf(city), 1);
                this.treeDataBDE[0].children.forEach((child) => {
                  if (child.label === city) {
                    child.children.forEach((child2) => {
                      if (child2.label !== chip) {
                        this.initSelectedBDE.push(child2.label);
                      }
                    });
                  }
                });
              }
            } else {
              this.initSelectedBDE = [];
              this.treeDataBDE[0].children.forEach((child) => {
                if (child.label !== chip) {
                  this.initSelectedBDE.push(child.label);
                }
              });
            }
          } else {
            this.initSelectedBDE.splice(this.initSelectedBDE.indexOf(chip), 1);
          }
          break;
        case "courses":
          if (this.initSelectedCourses.indexOf(chip) === -1) {
            this.initSelectedCourses = [];
            this.treeDataCourses[0].children.forEach((child) => {
              if (child.label !== chip) {
                this.initSelectedCourses.push(child.label);
              }
            });
          } else {
            this.initSelectedCourses.splice(this.initSelectedCourses.indexOf(chip), 1);
          }
          break;
        case "iimllevels":
          if (this.initSelectedLevelsIIML.indexOf(chip) === -1) {
            this.initSelectedLevelsIIML = [];
            this.treeDataLevelsIIML[0].children.forEach((child) => {
              if (child.label !== chip) {
                this.initSelectedLevelsIIML.push(child.label);
              }
            });
          } else {
            this.initSelectedLevelsIIML.splice(
              this.initSelectedLevelsIIML.indexOf(chip),
              1
            );
          }
          break;
        case "levels":
          if (this.initSelectedLevels.indexOf("All") !== -1) {
            this.initSelectedLevels = [];
            this.treeDataLevels[0].children.forEach((child) => {
              child.children.forEach((chi) => {
                if (chi.label !== chip) {
                  this.initSelectedLevels.push(chi.label);
                }
              });
            });
          } else if (this.initSelectedLevels.indexOf(chip) !== -1) {
            this.initSelectedLevels.splice(this.initSelectedLevels.indexOf(chip), 1);
          } else {
            let childProperty = "";
            this.treeDataLevels[0].children.forEach((child) => {
              if ("children" in child) {
                child.children.forEach((chi) => {
                  if (chi.label === chip) {
                    childProperty = child.label;
                  }
                });
              }
            });
            this.initSelectedLevels.splice(
              this.initSelectedLevels.indexOf(childProperty),
              1
            );
            this.treeDataLevels[0].children.forEach((child) => {
              if (child.label === childProperty) {
                if ("children" in child) {
                  child.children.forEach((chi) => {
                    if (chi.label !== chip) {
                      this.initSelectedLevels.push(chi.label);
                    }
                  });
                }
              }
            });
          }
      }
    },
    getSelectedParamsForRequests() {
      // let focus = this.initSelected;
      // if (this.initSelected !== null && this.initSelected !== undefined) {
      //   focus = this.initSelected.toLowerCase();
      // }
      let levels = [];
      let bde = [];
      let courses = [];
      let iimllevels = [];
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr" ||
        localStorage.getItem("role") === "wh2"
      ) {
        this.initSelectedBDE.forEach((sort) => {
        if (this.sort === "All") {
          this.treeDataBDE[0].children.forEach((child) => {
            child.children.forEach((acutal_spoc) => {
              bde.push(acutal_spoc.label);
            });
          });
        } else {
          let city = false;
          this.treeDataBDE[0].children.forEach((child) => {
            if (child.label === sort) {
              child.children.forEach((acutal_spoc) => {
                bde.push(acutal_spoc.label);
                console.log(acutal_spoc);
              });
              city = true;
            }
          });
          if (city) {
            return true;
          } else {
            bde.push(sort);
          }
        }
        });
        this.initSelectedBDESR.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDESR[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                bde.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDESR[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  bde.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              bde.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              bde.push(child.label);
            });
          } else {
            bde.push(sort);
          }
        });
      }
      this.initSelectedCourses.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach((child) => {
            courses.push(child.label);
          });
        } else {
          courses.push(sort);
        }
      });
      this.initSelectedLevelsIIML.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevelsIIML[0].children.forEach((child) => {
            iimllevels.push(child.label);
          });
        } else {
          iimllevels.push(sort);
        }
      });
      this.initSelectedLevels.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            levels.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            levels.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            levels.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            levels.push(child.label);
          });
        } else {
          levels.push(sort);
        }
      });
      let bdeid = [];
      let bdeNames = [];
      bde.forEach((spoc) => {
        this.rawBde.forEach((raw) => {
          if (raw.full_name === spoc) {
            bdeid.push(raw.id);
            bdeNames.push(raw.full_name);
          }
        });
      });
      let obj = {
        courses: courses.join(),
        levels: levels.join(),
        my_focus: "",
        spoc_ids: bdeid.join(),
        spoc_names: bdeNames.join(),
        iiml_levels: iimllevels.join(),
        whats_app_message_id: this.selectedWhatsappMessage,
        page: 1,
        daily_limit: this.daily_limit,
      };
      console.log("obj spocs", obj.spoc_names);
      if (this.initSelected !== null && this.initSelected !== undefined) {
        obj.my_focus = this.initSelected.toLowerCase();
      }
      // obj.my_focus = focus;
      return obj;
    },
    async search_API(page, copyfor) {
      let focus = this.initSelected;
      if (this.initSelected !== null && this.initSelected !== undefined) {
        focus = this.initSelected.toLowerCase();
      }
      let levels = [];
      let bde = [];
      let courses = [];
      let iimllevels = [];
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr" ||
        localStorage.getItem("role") === "wh2"
      ) {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                bde.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDE[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  bde.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              bde.push(sort);
            }
          }
        });
        this.initSelectedBDESR.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDESR[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                bde.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDESR[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  bde.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              bde.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              bde.push(child.label);
            });
          } else {
            bde.push(sort);
          }
        });
      }
      this.initSelectedCourses.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach((child) => {
            courses.push(child.label);
          });
        } else {
          courses.push(sort);
        }
      });
      this.initSelectedLevelsIIML.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevelsIIML[0].children.forEach((child) => {
            iimllevels.push(child.label);
          });
        } else {
          iimllevels.push(sort);
        }
      });
      this.initSelectedLevels.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            levels.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            levels.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            levels.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            levels.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            levels.push(child.label);
          });
        } else {
          levels.push(sort);
        }
      });
      let bdeid = [];
      bde.forEach((spoc) => {
        this.rawBde.forEach((raw) => {
          if (raw.full_name === spoc) {
            bdeid.push(raw.id);
          }
        });
      });
      let obj = {
        courses: courses.join(),
        levels: levels.join(),
        my_focus: focus,
        spoc_ids: bdeid.join(),
        iiml_levels: iimllevels.join(),
      };
      if (copyfor === true) {
        console.log("Is this even running? Yes it is.");
        console.log("obj in if", obj);
      } else {
        //messageError
        obj.whatsappMessage = this.selectedWhatsappMessage;
        obj.whats_app_message_id = this.selectedWhatsappMessage;
        if (obj.whatsappMessage === "") {
          this.messageError = true;
        } else {
          this.messageError = false;
          console.log("obj in else", obj);
          this.broadcastFiltersvalue = JSON.stringify(obj);
          console.log("JSON.stringify(obj)", this.broadcastFiltersvalue);
          // axios
          //   .post(
          //     `${constants.SERVER_API}getSearchedMwbLeads?page=${page}`,
          //     obj,
          //     {
          //       headers: {
          //         Authorization: `Bearer ${localStorage.userAccessToken}`,
          //       },
          //     }
          //   )
          //   .then((response) => {
          //     // console.log("laedas data", response);
          //     this.countsleads = response.data.total;
          //     response.data.data.forEach((lead) => {
          //       lead.engagements = [];
          //       lead.mobile = [];
          //       lead.emails = [];
          //     });
          //     this.currentTablePage = response.data.current_page;
          //     this.tablelinks = response.data.last_page;
          //     this.$store.commit("MOUNT_MWB_LEADS", response.data.data);
          //   })
          //   .catch((error) => {
          //     this.handleError(error);
          //   });
        }
      }
    },
    refreshdata() {
      EventBus.$emit("refresh-focus");
      this.initSelected = "";
      this.initSelectedBDE = [];
      this.initSelectedCourses = [];
      this.initSelectedLevels = [];
      this.initSelectedLevelsIIML = [];
      this.loadShow = false;
      this.countShow = false;
      // this.dateRange.startDate = "";
      // this.dateRange.endDate = "";
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr" ||
        localStorage.getItem("role") === "wh2"
      ) {
        this.getSpocAdmin();
      } else {
        this.getSpoc();
      }
    },
    // extractSpocBySubTeam(response) {
    //   var unique = response
    //     .map((name) => {
    //       return {
    //         count: 1,
    //         name: name.sub_team,
    //       };
    //     })
    //     .reduce((a, b) => {
    //       a[b.name] = (a[b.name] || 0) + b.count;
    //       return a;
    //     }, {});

    //   this.cities = [];
    //   for (var key in unique) {
    //     if (unique.hasOwnProperty(key)) {
    //       if(key != "IIML-FA" && key != "null") {
    //         this.initSelectedBDE.push(key);
    //       }

    //     }
    //   }
    //   console.log("gsdyuas", this.cities);
    //   this.cities = this.sortArrayAlphabetically(this.cities);
    //   this.selected_city = this.roles[0];
    // },
    getSpoc() {
      this.initSelectedBDE = [];
      let url = ``;
      if (this.select_role === "CM") {
        url = `${constants.SERVER_API}getSpocs`;
      } else if (this.select_role === "IIML") {
        url = `${constants.SERVER_API}getExed`;
      }
      axios
        .get(url, {
          headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
        })
        .then((response) => {
          // console.log(response);
          // console.log("spocs", response.data.spocs);
          this.rawBde = [];
          let usercity = "";
          this.searchIn.id = response.data.logged_in_user_id;
          this.searchIn.name = response.data.logged_in_user_name;
          this.extractSpocBySubTeam(response.data.spocs);
          var unique = response.data.spocs
            .map((name) => {
              return {
                count: 1,
                name: name.city,
              };
            })
            .reduce((a, b) => {
              a[b.name] = (a[b.name] || 0) + b.count;
              return a;
            }, {});
          // console.log(unique, "Unique");
          this.SpocCityPrefilled = [];
          for (var key in unique) {
            if (unique.hasOwnProperty(key)) {
              this.SpocCityPrefilled.push(key.charAt(0).toUpperCase());
            }
          }
          response.data.spocs.forEach((spoc) => {
            if (this.getrole("cm1")) {
              if (spoc.city === usercity) {
                this.rawBde.push(spoc);
              }
            } else if (this.getrole("exed") || this.getrole("exed2")) {
              this.rawBde.push(spoc);
            }
          });
          this.treeDataBDE[0].children = [];
          this.rawBde.forEach((data) => {
            var obj = {
              id: data.full_name,
              label: data.full_name,
            };
            this.treeDataBDE[0].children.push(obj);
          });
          this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(
            this.treeDataBDE[0].children
          );
          if (
            localStorage.getItem("role") === "cm1" ||
            localStorage.getItem("role") === "exed" ||
            localStorage.getItem("role") === "exed2"
          ) {
            // console.log(this.treeDataBDE[0].children);
            this.initSelectedBDE.push(this.searchIn.name);
          }
          this.search_API(1);
        })
        .catch((error) => {
          this.handleError(error);
        });
    },
    getSpocAdmin() {
      let url = ``;
      if (this.select_role === "CM") {
        url = `${constants.SERVER_API}getSpocs`;
        axios
          .get(url, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          })
          .then((response) => {
            console.log("response cm", response);
            this.searchIn.id = response.data.logged_in_user_id;
            this.searchIn.name = response.data.logged_in_user_name;
            response.data.spocs = this.ExcluingXSpocs(response.data.spocs);
            // response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
            this.rawBde = response.data.spocs;
            this.treeDataBDE[0].children = [];
            this.rawcities = [];
            var unique = response.data.spocs
              .map((name) => {
                return {
                  count: 1,
                  name: name.city,
                };
              })
              .reduce((a, b) => {
                a[b.name] = (a[b.name] || 0) + b.count;
                return a;
              }, {});
            for (var key in unique) {
              if (unique.hasOwnProperty(key)) {
                this.rawcities.push(key);
                var obj = {
                  id: key,
                  label: key,
                  children: [],
                };
                this.treeDataBDE[0].children.push(obj);
              }
            }
            response.data.spocs.forEach((server_spoc) => {
              this.treeDataBDE.forEach((bde) => {
                bde.children.forEach((bdechid) => {
                  if (bdechid.label === server_spoc.city) {
                    var obj = {
                      id: server_spoc.full_name,
                      label: server_spoc.full_name,
                    };
                    bdechid.children.push(obj);
                  }
                });
              });
            });
            this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(
              this.treeDataBDE[0].children
            );
            if (this.rawBde.indexOf(this.searchIn) !== -1) {
              this.initSelectedBDE.push(this.searchIn.name);
            }
            // this.search_API(1);
          })
          .catch((error) => {
            this.handleError(error);
          });
      } else if (this.select_role === "IIML") {
        url = `${constants.SERVER_API}getExed`;
        axios
          .get(url, {
            headers: { Authorization: `Bearer ${localStorage.userAccessToken}` },
          })
          .then((response) => {
            console.log("response cm", response);
            this.searchIn.id = response.data.logged_in_user_id;
            this.searchIn.name = response.data.logged_in_user_name;
            response.data.spocs = this.ExcluingXSpocs(response.data.spocs);
            // response.data.spocs = this.removeUnwwantedSpocs(response.data.spocs);
            this.rawBde = response.data.spocs;
            this.treeDataBDE[0].children = [];
            this.rawcities = [];
            var unique = response.data.spocs
              .map((name) => {
                return {
                  count: 1,
                  name: name.sub_team,
                };
              })
              .reduce((a, b) => {
                a[b.name] = (a[b.name] || 0) + b.count;
                return a;
              }, {});
            for (var key in unique) {
              if (unique.hasOwnProperty(key)) {
                this.rawcities.push(key);

                // console.log('this rawcities',this.treeDataBDE)
                var obj = {
                  id: key,
                  label: key,
                  children: [],
                };
                if (
                  obj.label !== "null" &&
                  obj.label !== "undefined" &&
                  obj.label !== "IIML-FA" &&
                  obj.label !== "IIML-SF" &&
                  obj.label !== "IIML-FT"
                ) {
                  this.treeDataBDE[0].children.push(obj);
                }
              }
            }
            response.data.spocs.forEach((server_spoc) => {
              this.treeDataBDE.forEach((bde) => {
                bde.children.forEach((bdechid) => {
                  if (bdechid.label === server_spoc.sub_team) {
                    var obj = {
                      id: server_spoc.full_name,
                      label: server_spoc.full_name,
                    };
                    bdechid.children.push(obj);
                  }
                });

                // console.log('bde.children',bde.children)
              });
            });
            this.treeDataBDE[0].children = this.sortNestedArrayAlphabetically(
              this.treeDataBDE[0].children
            );
            if (this.rawBde.indexOf(this.searchIn) !== -1) {
              this.initSelectedBDE.push(this.searchIn.name);
            }
            // this.search_API(1);
          })
          .catch((error) => {
            this.handleError(error);
          });
      }
    },
    mergeAllSearch() {
      this.searchCriteria = [];
      this.searchCriteria.push("divider");
      if (
        localStorage.getItem("role") === "cm4" ||
        localStorage.getItem("role") === "wc" ||
        localStorage.getItem("role") === "ac2" ||
        localStorage.getItem("role") === "fe" ||
        localStorage.getItem("role") === "cmca" ||
        localStorage.getItem("role") === "baca" ||
        localStorage.getItem("role") === "hr" ||
        localStorage.getItem("role") === "wh2"
      ) {
        // this.initSelectedBDE.forEach((sort) => {
        if (this.initSelectedBDE === "All") {
          this.treeDataBDE[0].children.forEach((child) => {
            child.children.forEach((acutal_spoc) => {
              this.searchCriteria.push(acutal_spoc.label);
            });
          });
        } else {
          let city = false;
          this.treeDataBDE[0].children.forEach((child) => {
            if (child.label === this.initSelectedBDE) {
              child.children.forEach((acutal_spoc) => {
                // this.searchCriteria.push(acutal_spoc.label);
                console.log(acutal_spoc);
              });
              city = true;
            }
          });
          if (city) {
            return true;
          } else {
            console.log("this.initSelectedBDE", this.initSelectedBDE);
						this.initSelectedBDE.forEach((element) => {
							this.searchCriteria.push(element);
						});
          }
        }
        // });
        this.initSelectedBDESR.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDESR[0].children.forEach((child) => {
              child.children.forEach((acutal_spoc) => {
                this.searchCriteria.push(acutal_spoc.label);
              });
            });
          } else {
            let city = false;
            this.treeDataBDESR[0].children.forEach((child) => {
              if (child.label === sort) {
                child.children.forEach((acutal_spoc) => {
                  this.searchCriteria.push(acutal_spoc.label);
                });
                city = true;
              }
            });
            if (city) {
              return true;
            } else {
              this.searchCriteria.push(sort);
            }
          }
        });
      } else {
        this.initSelectedBDE.forEach((sort) => {
          if (sort === "All") {
            this.treeDataBDE[0].children.forEach((child) => {
              this.searchCriteria.push(child.label);
            });
          } else {
            this.searchCriteria.push(sort);
          }
        });
      }
      this.searchCriteria.push("divider");
      console.log(this.initSelectedCourses);
      this.initSelectedCourses.forEach((sort) => {
        if (sort === "All") {
          this.treeDataCourses[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
      this.searchCriteria.push("divider");
      this.initSelectedLevels.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P1") {
          this.treeDataLevels[0].children[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P2") {
          this.treeDataLevels[0].children[1].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else if (sort === "P3") {
          this.treeDataLevels[0].children[2].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
      this.searchCriteria.push("divider");
      if (this.initSelected !== "" && this.initSelected !== undefined) {
        this.searchCriteria.push(this.initSelected);
      }
      this.searchCriteria.push("divider");
      this.searchCriteria.push("divider");
      this.initSelectedLevelsIIML.forEach((sort) => {
        if (sort === "All") {
          this.treeDataLevelsIIML[0].children.forEach((child) => {
            this.searchCriteria.push(child.label);
          });
        } else {
          this.searchCriteria.push(sort);
        }
      });
    },
    search() {
      this.search_API();
    },
    nodechecked(node, v) {
      alert("that a node-check envent ..." + node.title + v);
    },
    async asyncLoad(node) {
      const { checked = false } = node;
      this.$set(node, "loading", true);
      let pro = await new Promise((resolve) => {
        setTimeout(resolve, 2000, [
          { title: "test1", async: true },
          { title: "test2", async: true },
          { title: "test3" },
        ]);
      });
      if (!node.hasOwnProperty("children")) {
        this.$set(node, "children", []);
      }
      node.children.push(...pro);
      this.$set(node, "loading", false);
      if (checked) {
        this.$refs.tree.childCheckedHandle(node, checked);
      }
    },
    showTreeBox() {
      return this.treeDataBDE;
    },
    EmitEvent(emit, payload) {
      if (payload === "") {
        EventBus.$emit(emit);
      } else {
        EventBus.$emit(emit, payload);
      }
    },
    getwhatsappMessages() {
      //http://milescmback.2x2.ninja/api/whatsAppBroadcast
      let url = `${constants.SERVER_API}WhatsappBroadcastMessage`;
      axios
        .get(url, {
          headers: {
            Authorization: `Bearer ${localStorage.userAccessToken}`,
          },
        })
        .then((response) => {
          console.log("whatsapp messages", response.data);
          this.whatsappMessages = response.data;
        });
    },
    ExcluingXSpocs(response) {
      let excludeIds = [
        479,
        480,
        481,
        482,
        483,
        484,
        485,
        486,
        487,
        178,
        179,
        181,
        182,
        186,
        187,
        188,
        189,
        190,
        255,
        387,
        412,
        468,
        504,
        507,
        515,
        516,
        517,
        518,
        519,
        520,
        521,
        522,
        523,
        524,
        475,
      ];
      let newData = response.filter((v) => {
        return !excludeIds.includes(v.id);
      });
      return newData;
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/scss/vuesax/pages/dropdown.scss";
.headr {
  color: gray;
  font-size: 25px;
  margin-bottom: 2%;
}
.gap {
  margin-bottom: 4%;
}
.inicolor {
  color: gray;
}
.cardtext {
  color: gray;
  font-size: 20px;
}
.tree3 {
  padding: 10px;
  box-sizing: border-box;
  border: 1px dotted #ccccdd;
  overflow: auto;
  height: 500px;
  width: 300px;
}
.vs-dropdown--menu {
  width: 300px;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
.gap {
  margin-right: 1%;
}
.examplex {
  display: flex;
  align-items: center;
  justify-content: center;
}
.a-icon {
  outline: none;
  text-decoration: none !important;
  display: flex;
  align-items: center;
  justify-content: center;
}
i {
  font-size: 18px;
}
.vl {
  border-left: 1px solid black;
  height: 35px;
}
#style-1::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: #f5f5f5;
}
.scrollbar {
  overflow-y: scroll;
}
.buttonrow {
  // justify-content: center;
  margin: 5px;
  justify-content: flex-end;
}
.buttonlist {
  width: 200px;
}
.con-vs-dropdown--menu {
  z-index: 500000;
}
</style>
